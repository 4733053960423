import Image from '@uc-common/image';
import React from 'react';
import { Box, Flex } from 'rebass/styled-components';

const LogoBox = ({ src, width }) => (
  <Box
    as={Image}
    src={src}
    mb={['26px']}
    width={width}
    sx={{
      height: 'auto',
    }}
  />
);

const Section = ({ columns }) => (
  <Flex
    flexDirection="column"
    alignItems="center"
    sx={{
      maxWidth: '1120px',
      width: '100%',
    }}
  >
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: ['repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(6, 1fr)'],
        gridAutoRows: 'max-content',
        gridGap: ['40px', '40px'],
        justifyItems: 'center',
        alignItems: 'center',
      }}
    >
      {columns}
    </Box>
  </Flex>
);

export const LogoGrid = () => {
  return (
    <Flex flexDirection="column" px={['15px', '32px', '45px', '80px']} pt={['60px', '100px']}>
      <Section
        columns={
          <>
            <LogoBox
              src="https://ucarecdn.com/609f7ffd-10da-4c52-9274-581ccecf1c88/image21Traced.svg"
              width="69px"
            />
            <LogoBox
              src="https://ucarecdn.com/6c6889f7-c902-45ed-a620-464433caab20/WordPresslogo.svg"
              width="166px"
            />
            <LogoBox
              src="https://ucarecdn.com/bbd1643e-7c55-4e19-adca-1d637002fb40/AWSlogo.svg"
              width="58px"
            />
            <LogoBox
              src="https://ucarecdn.com/e0737963-cb32-422f-86ea-1d1f189e7238/Akamailogo.svg"
              width="108px"
            />
            <LogoBox
              src="https://ucarecdn.com/ec58b9ca-7897-488b-abbf-1238db8bfc43/ganlogo1.svg"
              width="48px"
            />
            <LogoBox
              src="https://ucarecdn.com/720bdf6f-6111-4517-8ed9-e1da784f256c/nocodelogo.svg"
              width="86px"
            />
            <LogoBox
              src="https://ucarecdn.com/58ae11ce-bde3-428f-9d09-a25219114ca2/JotFormlogo.svg"
              width="101px"
            />
            <LogoBox
              src="https://ucarecdn.com/ffce4889-651f-45a9-8416-79137c26e604/founderlylogo.svg"
              width="144px"
            />
            <LogoBox
              src="https://ucarecdn.com/dc30c153-8ce1-441e-baae-b832ff3fbf8a/Marketologo.svg"
              width="108px"
            />
            <LogoBox
              src="https://ucarecdn.com/ce7b5e3b-5fb0-4fa9-b637-b59b3b5aaa2b/ucombinatorbw.svg"
              width="104px"
            />
            <LogoBox
              src="https://ucarecdn.com/3e20cc2b-816d-46ef-8e12-62a2724ced6b/Hackquarters.svg"
              width="130px"
            />
            <LogoBox
              src="https://ucarecdn.com/d851c562-2862-42a3-8e9e-6c22e417e376/intrepid.svg"
              width="95px"
            />
          </>
        }
      />
    </Flex>
  );
};
