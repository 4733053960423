import { useImgSizes } from '@uc-common/use-img-sizes';
import { useImgSrcSet } from '@uc-common/use-img-srcset';
import Image from '@uc-common/image';
import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';

const Avatar = ({ src }) => {
  const widths = [92, 82, 90, 99];

  const [srcSet, fallbackSrc] = useImgSrcSet({
    src,
    widths,
    filename: 'avatar.jpg',
    mapper: ({ src, width, dpi, filename }) =>
      `${src}-/scale_crop/${width}x${width}/smart/-/progressive/yes/-/format/auto/-/quality/${
        dpi > 1 ? 'lightest' : 'normal'
      }/${filename} ${width}w`,
  });
  const sizes = useImgSizes(widths);

  return (
    <Box
      as={Image}
      srcSet={srcSet}
      src={fallbackSrc}
      sizes={sizes}
      sx={{ borderRadius: '50px', width: widths, height: widths }}
    />
  );
};

export const CaseStudy = () => (
  <Flex
    alignItems="center"
    flexDirection="column"
    mt={['50px', '10px']}
    pr={['0px', '32px', '45px', '80px']}
    pl={['15px', '32px', '45px', '80px']}
    mb={['30px', '40px']}
  >
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: ['1fr', '1fr 300px'],
        gridGap: ['40px', '40px'],
        maxWidth: ['auto', '1120px'],
        width: '100%',
        borderBottom: '1px solid #E6E6E6',
        pb: ['40px', '54px', '70px', '80px'],
      }}
    >
      <Box>
        <Text fontSize={['18px', '20px', '22px', '24px']} lineHeight="150%">
          &ldquo;The &quot;return on investment&quot; of Uploadcare is self-evident from day one,
          and that&apos;s just from the time savings. I can think of no reason why anyone should
          reinvent the wheel or even attempt to roll out their own solution from scratch. Uploadcare
          has brought file uploading, processing, and delivery to perfection.&rdquo;
        </Text>
      </Box>
      <Flex
        pr={['15px', '0px']}
        maxWidth={['100%', '170px', '200px', '300px']}
        width="100%"
        justifyContent="space-between"
        flexDirection="column"
      >
        <Box
          mb="11px"
          justifyContent={['flex-end', 'flex-end', 'flex-start']}
          display={['none', 'block']}
          width={['100%', '170px', '200px', '300px']}
        >
          <Box
            as={Image}
            src="https://ucarecdn.com/609f7ffd-10da-4c52-9274-581ccecf1c88/image21Traced.svg"
            mb={['26px']}
            width={[69, 80]}
            color="#B5B5B5"
          />
        </Box>
        <Box
          sx={{
            alignItems: 'flex-end',
            display: 'grid',
            gridGap: ['10px', '10px', '0px', '14px'],
            gridAutoFlow: ['column', 'row', 'column'],
            gridAutoColumns: ['1fr 1fr', '1fr', '1fr 90px', '1fr 99px'],
            gridTemplateRow: '1fr 1fr',
          }}
        >
          <Box display={['flex', 'none']} sx={{ gridArea: ['1 / 1', 'initial'] }}>
            <Box
              as={Image}
              src="https://ucarecdn.com/609f7ffd-10da-4c52-9274-581ccecf1c88/image21Traced.svg"
              mb={['26px']}
              width={[69, 80]}
              color="#B5B5B5"
            />
          </Box>
          <Text
            fontSize={['18px', '18px', '18px', '20px']}
            lineHeight={['24px', '24px', '24px', '27px']}
            color="#B5B5B5"
            sx={{
              whiteSpace: 'nowrap',
              gridRow: ['2', 'initial'],
              textAlign: ['left', 'right', 'left'],
            }}
          >
            Terence Hong,
            <br /> CEO
          </Text>
          <Flex
            alignItems="flex-end"
            justifyContent={['flex-end', 'flex-end', 'flex-start']}
            sx={{ gridRow: ['span 2', 'initial'] }}
          >
            <Avatar src="https://ucarecdn.com/f5bcbf71-40ae-4d88-9d13-4257699b4d7e/" />
          </Flex>
        </Box>
      </Flex>
    </Box>
  </Flex>
);

export const Testimonial = ({ ppc = false }) => {
  return <CaseStudy ppc={ppc} />;
};
