import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box } from 'rebass/styled-components';
import { PaddingEnum } from '../b-layout';
import { useCurrentBreakpoint } from '../use-current-breakpoint/useCurrentBreakpoint';
import { BlImage } from '../bl-image';

export const VisualBig = ({ srcMap = [], src, sx, alt = '', ...props }) => {
  const breakPoint = useCurrentBreakpoint();
  const [srcFromMap, setSrcFromMap] = useState('');

  useEffect(() => {
    const srcFromMap = srcMap[breakPoint] || srcMap[srcMap.length - 1];
    setSrcFromMap(srcFromMap);
  }, [breakPoint, srcMap]);

  return (
    <Box
      sx={{
        width: '100%',
        px: PaddingEnum.HORIZONTAL_NORMAL,
        ...sx,
      }}
      {...props}
    >
      <Box
        as={BlImage}
        data-blink-src={src || srcFromMap}
        sx={{
          width: '100%',
          objectFit: 'cover',
        }}
        alt={alt}
      />
    </Box>
  );
};

VisualBig.propTypes = {
  src: PropTypes.string,
  srcMap: PropTypes.arrayOf(PropTypes.string),
  sx: PropTypes.object,
  alt: PropTypes.string,
};
