import { useMemo } from 'react';
import { useTheme } from 'styled-components';

const hasOnlyDigits = (str) => /^\d+$/g.test(str);

const getUnits = (width) => (typeof width === 'string' && !hasOnlyDigits(width) ? '' : 'px');

export const useImgSizes = (widths) => {
  const { breakpoints } = useTheme();

  return useMemo(
    () =>
      [
        ...widths
          .map((width, idx) => {
            const units = getUnits(width);
            const widthValue = `${width}${units}`;
            const mediaQuery = idx > 0 ? `(min-width: ${breakpoints[idx - 1]}) ` : '';

            return `${mediaQuery}${widthValue}`;
          })
          .reverse(),
      ].join(', '),
    [widths, breakpoints]
  );
};
