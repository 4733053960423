import { Accordeon, AccordeonItem } from '@uc-common/b-accordeon';

import {
  LeadForm,
  LeadFormTextField,
  LeadFormCheckboxField,
  LeadFormSelectNativeField,
  LeadFormFieldKindEnum,
} from '@uc-common/b-leadform';
import { FORM_ID_ENUM } from '@uc-common/use-lead-form';
import { Box } from 'rebass/styled-components';
import { Head, HeadVariantsEnum } from '@uc-common/b-head';

import { Layout as BLayout, PaddingEnum, Section } from '@uc-common/b-layout';

import { VisualBig } from '@uc-common/b-visual-big';
import { Button, ButtonTypeEnum } from '@uc-common/button';
import React from 'react';
import { Link } from '~/src/components/Link';
import { Navbar } from '../../components/Navbar';
import { Footer } from '../../components/Footer';

import { CommonLayout } from '../../components/Layout/CommonLayout';
import { PartnershipColumns } from '../../components/forBuilder/partnership-columns';
import { LogoGrid } from '../../components/forBuilder/partnership-logo-grid';
import { Testimonial } from '../../components/forBuilder/partnership-testimonial';

export const frontmatter = {
  title: 'Uploadcare Partnership Program: Join Us Today',
  description:
    'Empower tech teams with our top-notch file handling infrastructure and redeem exclusive perks.',
  noindex: false,
};

export default ({ location }) => {
  return (
    <CommonLayout meta={frontmatter} pathName={location.pathname}>
      <BLayout header={<Navbar />} footer={<Footer />}>
        <Section
          sx={{
            paddingTop: PaddingEnum.VERTICAL_S,
          }}
        >
          <Head
            variant={HeadVariantsEnum.V1}
            title="Partner with Uploadcare. Let’s team up and scale together"
            description="Join us and grow your business by shipping extra value and expertise to your customers. With exclusive access to our tools, you’ll save months of engineering time and help teams focus on what matters most."
            cta={
              <Button as={Link} to="#form" type={ButtonTypeEnum.DARK}>
                Become a partner
              </Button>
            }
          />
        </Section>
        <Section sx={{ paddingTop: PaddingEnum.VERTICAL_XL }}>
          <PartnershipColumns />
        </Section>
        <Section sx={{ paddingTop: PaddingEnum.VERTICAL_XL }}>
          <Head
            variant={HeadVariantsEnum.V3}
            title="Why team up?"
            description="As an Uploadcare partner, you’ll provide your customers with the best-in-class file handling infrastructure and redeem exclusive perks"
          />
          <Accordeon sx={{ paddingTop: PaddingEnum.VERTICAL_L }}>
            <AccordeonItem
              title="A top-notch solution"
              description="Uploadcare saves up to 2 years of engineering time on building a fully fledged file uploading, processing, and delivery system. Refer your clients to our out-of-the-box solution and witness the impact."
              illustrationSrc="https://ucarecdn.com/71d4cf9c-61eb-4b11-af9e-fb80f9f45d99/1.svg"
            />
            <AccordeonItem
              title="At the best price"
              description="Our partners enjoy custom usage-based plans and discounts. Slice up to 20% off for yourself, your clients, and referrals, or get a recurring commission."
              illustrationSrc="https://ucarecdn.com/22436407-e646-4045-b05c-6ed60ed0fca0/2.svg"
            />
            <AccordeonItem
              title="With our full support"
              description="Don’t hesitate to refer your clients to Uploadcare: they will be in good hands. We provide ongoing 24/7 engineering support along with extensive docs and a community forum."
              illustrationSrc="https://ucarecdn.com/c4478b48-97d1-4624-a624-7c79a05ee71f/3.svg"
            />
            <AccordeonItem
              title="And ongoing collaboration"
              description="As an Uploadcare partner, you’ll get deep-dive product demos and full access to our industry insights. On top of that, you can get in front of our 25,000+ customers in ecommerce, SaaS, CMS, and other markets."
              illustrationSrc="https://ucarecdn.com/bc2adce5-24ca-4ca6-bef1-c750f6fe4d08/4.svg"
            />
          </Accordeon>
        </Section>

        <Section sx={{ paddingTop: PaddingEnum.VERTICAL_XL }}>
          <Testimonial />
        </Section>
        <LogoGrid />
        <Section
          sx={{
            paddingTop: PaddingEnum.VERTICAL_L,
          }}
        >
          <VisualBig
            src="https://ucarecdn.com/ccc56aaf-49dc-4a9d-9be8-d689ff7db141/Group2756.svg"
            sx={{
              paddingTop: PaddingEnum.VERTICAL_M,
              minHeight: ['150px', '330px', '420px', '490px'],
            }}
          />
        </Section>
        <Section
          id="form"
          sx={{
            py: PaddingEnum.VERTICAL_XL,
          }}
        >
          <LeadForm
            id={FORM_ID_ENUM.PARTNERSHIP}
            title="Ready to move some mountains together?"
            illustrationSrc="https://ucarecdn.com/8134537d-cdf1-4a44-8a01-7cf63d193446/BenefitsVisual12805.svg"
            formTitle="Become a partner now"
            description=""
            formCta="Apply now"
          >
            <LeadFormTextField kind={LeadFormFieldKindEnum.NAME} placeholder="Full name" />
            <LeadFormTextField kind={LeadFormFieldKindEnum.EMAIL} placeholder="Email" />
            <LeadFormTextField kind={LeadFormFieldKindEnum.WEBSITE} placeholder="Your website" />
            <LeadFormSelectNativeField
              kind={LeadFormFieldKindEnum.CUSTOM}
              name="Partnership_type"
              placeholder="Type of partnership"
              required="Choose type of partnership"
            >
              <option value="solution">Solution</option>
              <option value="technology">Technology</option>
              <option value="affiliate">Affiliate</option>
            </LeadFormSelectNativeField>
            <Box
              as={LeadFormTextField}
              forwardedAs="textarea"
              kind={LeadFormFieldKindEnum.CUSTOM}
              name="Discuss"
              placeholder="Any questions so far?"
              required={false}
              sx={{
                resize: 'vertical',
                minHeight: '100px',
              }}
            />
            <LeadFormCheckboxField
              kind={LeadFormFieldKindEnum.PRIVACY_POLICY_CHECKBOX}
              label={
                <>
                  I agree to the{' '}
                  <a
                    href="/about/privacy-policy/" // eslint-disable-next-line react/jsx-no-target-blank
                    target="_blank"
                  >
                    Privacy Policy
                  </a>{' '}
                  terms
                </>
              }
            />
          </LeadForm>
        </Section>
      </BLayout>
    </CommonLayout>
  );
};
