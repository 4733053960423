import Image from '@uc-common/image';
import { Button, ButtonTypeEnum } from '@uc-common/button';
import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Link } from '~/src/components/Link';

const ColumnText = (props) => (
  <Text
    as="h3"
    fontSize={['20px', '20px', '22px']}
    lineHeight="1.3em"
    fontWeight="bold"
    {...props}
  />
);

const SectionColumn = ({ icon, title, text, textGrey, cta }) => (
  <Flex justifyContent="space-between" flexDirection="column" width="100%" pr={['0px', '30px']}>
    <Flex alignItems="flex-start" flexDirection="column" width="100%" pr={['0px', '30px']}>
      <Box
        as={Image}
        src={icon}
        mb={['26px']}
        sx={{
          width: ['150px'],
          height: ['80px'],
        }}
      />
      <Box paddingBottom="20px">
        <ColumnText color="#000000">{title}</ColumnText>
      </Box>
      <Box width="100%">
        <Text fontSize={['16px', '16px', '18px']} lineHeight="1.4em" color="#000000" as="p">
          {text}
        </Text>
        <Text fontSize={['16px', '16px', '18px']} lineHeight="1.4em" color="#b5b5b5" as="p">
          {textGrey}
        </Text>
      </Box>
    </Flex>
    <Box paddingTop="20px" alignSelf="baseline" width={['100%', 'auto']}>
      {cta}
    </Box>
  </Flex>
);

const Section = ({ columns }) => (
  <Flex
    flexDirection="column"
    alignItems="center"
    sx={{
      maxWidth: '1120px',
      width: '100%',
      pb: [60, 60, 70, 80],
      borderBottom: '1px solid #DEE4EA',
    }}
  >
    <Flex flexDirection="column" width="100%">
      <Box
        sx={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
          gridGap: ['80px', '8px'],
        }}
      >
        {columns}
      </Box>
    </Flex>
  </Flex>
);

export const PartnershipColumns = () => {
  return (
    <Flex flexDirection="column" px={['15px', '32px', '45px', '80px']} pt={['30px', '40px']}>
      <Section
        columns={
          <>
            <SectionColumn
              icon="https://ucarecdn.com/3bd4cc00-5945-40ea-86e9-88d9616887d5/Frame19790.svg"
              title="Solution Partner"
              text="Empower your clients with our best-in-class infrastructure and enjoy significant discounts."
              textGrey=" Designed for dev and consulting companies, agencies, integrators, and service providers."
              cta={
                <Button as={Link} to="#form" type={ButtonTypeEnum.DARK}>
                  Become a partner
                </Button>
              }
            />
            <SectionColumn
              icon="https://ucarecdn.com/f2039739-6dc1-4bbc-a499-7224f9ff2431/Group1988.svg"
              title="Technology Partner"
              text="Integrate Uploadcare to automate file handling at scale. Unlock custom feature requests and usage-based pricing plans."
              textGrey=" Designed for CDNs, hosting providers, CMSs, and media-heavy platforms."
              cta={
                <Button as={Link} to="#form" type={ButtonTypeEnum.DARK}>
                  Become a partner
                </Button>
              }
            />
            <SectionColumn
              icon="https://ucarecdn.com/d23b2067-1698-4eb3-9f02-fd119e36e90a/Group2023.svg"
              title="Affiliate Partner"
              text="Refer Uploadcare and receive a 10% commission on all payments within the first 12 months of implementation."
              textGrey=" Designed for influencers and community leaders, but there’s no audition, so go for it!"
              cta={
                <Button
                  as={Link}
                  to="https://uploadcare.getrewardful.com/signup "
                  type={ButtonTypeEnum.DARK}
                >
                  Become a partner
                </Button>
              }
            />
          </>
        }
      />
    </Flex>
  );
};
