import { useMemo } from 'react';

const DPI_MULTIPLIERS = [1, 2];
const DEFAULT_WIDTH_THRESHOLD = 100;

const DEFAULT_MAPPER = ({ src, width, dpi, filename }) =>
  `${src}-/resize/${width}x/-/progressive/yes/-/format/auto/-/quality/${
    dpi > 1 ? 'lightest' : 'normal'
  }/${filename} ${width}w`;

const multiplyByDpi = (widths) =>
  widths.reduce((acc, width) => {
    const width2use = parseInt(width, 10);

    return [...acc, ...DPI_MULTIPLIERS.map((dpi) => ({ width: width2use * dpi, dpi }))];
  }, []);

const dedupeByThreshold = (multiplied, widthThreshold) =>
  multiplied.reduce((acc, item) => {
    // select items with near width by threshold
    const dupesByThreshold = multiplied.filter(
      ({ width }) => Math.abs(width - item.width) < widthThreshold
    );
    const hasDupes = dupesByThreshold.length > 1;

    if (!hasDupes) {
      return [...acc, item];
    }

    // choose higher width & lower dpi (lower compression) as best dupe
    const bestDupe = dupesByThreshold.sort((a, b) => b.width - a.width || a.dpi - b.dpi)[0];
    const isBest = item === bestDupe;

    if (isBest) {
      return [...acc, item];
    }

    return acc;
  }, []);

const getFallbackSrc = (src, filename, widths, mapper) => {
  const maxWidth = Math.max(...widths);
  return mapper({
    src,
    width: maxWidth,
    dpi: 1,
    filename,
  }).split(' ')[0];
};

export const useImgSrcSet = ({
  src,
  widths,
  widthThreshold = DEFAULT_WIDTH_THRESHOLD,
  mapper = DEFAULT_MAPPER,
  filename = '',
}) => {
  return useMemo(() => {
    const multiplied = multiplyByDpi(widths);
    const deduped = dedupeByThreshold(multiplied, widthThreshold);
    const mapped = deduped
      .sort((a, b) => b.width - a.width)
      .map(({ width, dpi }) => mapper({ src, width, dpi, filename }));

    const srcSet = mapped.join(', ');
    const fallbackSrc = getFallbackSrc(src, filename, widths, mapper);

    return [srcSet, fallbackSrc];
  }, [filename, mapper, src, widthThreshold, widths]);
};
